import * as R from 'ramda';
import { ValidatorUtils } from './utils';

export const notEqualToValidator = {
    message(field: string, args: any) {
        return `'${ValidatorUtils.prettifyName(field)}' cannot be equal to ${args[0]}`;
    },
    validate: (value: any, args: any) => {
        if (R.isNil(value) || R.isEmpty(value) || R.isNil(args) || R.isEmpty(args) || isNaN(args[0])) return false;
        return Number(value) !== Number(args[0]);
    },
};
